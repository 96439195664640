/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
}

p {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: "Open Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(../fonts/InterVariable.ttf) format("truetype");
}
* {
  font-family: "Open Sans", Helvetica, sans-serif;
  box-sizing: border-box;
  color: #486284;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.primary {
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #486284;
  background: #486284;
  color: #FFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.primary:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.primary:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.primary:disabled {
  background: #CED7E4;
}

.secondary {
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: transparent;
  color: var(--Neutral-10, #486284);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.secondary:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.secondary-solid {
  background-color: #ffffff;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #486284;
  color: #486284;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.secondary-solid:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.secondary-solid:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--Light-B, #CED7E4);
  height: 36px;
  padding: 10px;
}
.tag button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
}
.tag span {
  color: var(--Dark-B, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.modal {
  border-radius: 8px;
  background: #EFF2F6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 28px;
}
.modal .close-modal {
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
}

.dashboard {
  font-family: "Open Sans";
  color: black;
  color: #486284;
  padding: 70px 50px 0 50px;
}
.dashboard h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
}
.dashboard .dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dashboard .dashboard-header h2 {
  font-size: 21px;
  font-weight: 500;
  line-height: 27.34px;
  letter-spacing: 0.150000006px;
}
.dashboard .dashboard-header button {
  width: 186px;
}
.dashboard .dashboard-find-presets {
  display: flex;
  gap: 27px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 42px;
}
.dashboard .dashboard-find-presets .tags {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard .dashboard-find-presets label {
  color: var(--Neutral-10, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 10px;
}
.dashboard .dashboard-find-presets .select-container {
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  height: 48px;
  flex-grow: 1;
  width: 360px;
  padding: 12px 20px;
  position: relative;
}
.dashboard .dashboard-find-presets .select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  width: 100%;
}
.dashboard .dashboard-find-presets .select-container select:focus {
  outline: none;
}
.dashboard .dashboard-find-presets .select-container img {
  position: absolute;
  right: 12px;
  top: 16px;
}
.dashboard .dashboard-find-presets .add {
  border-radius: 10px;
  border: 1px solid var(--Dark-B, #486284);
  background: var(--Light-B-2, #EFF2F6);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.dashboard .tabs {
  width: 100%;
  border-bottom: 1px solid #90A3BF;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .tabs button {
  flex: 1;
  background: transparent;
  border: none;
  padding-bottom: 35px;
  position: relative;
}
.dashboard .tabs button span {
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #486284;
  opacity: 0.6;
}
.dashboard .tabs button.active:after {
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  background-color: #486284;
  position: absolute;
  bottom: 0;
}
.dashboard .tabs button.active span {
  opacity: 1;
}
.dashboard .tabpanel[role=tabpanel] {
  position: relative;
}
.dashboard .tabpanel[role=tabpanel].is-hidden {
  display: none;
}
.dashboard .dashboard-title-card {
  display: flex;
  gap: 64px;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 184px;
  margin-bottom: 35px;
  width: 100%;
  position: relative;
}
.dashboard .dashboard-title-card .title-card-cover img {
  height: auto;
  width: 177px;
  border-radius: 24px;
}
.dashboard .dashboard-title-card .title-card-info {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboard .dashboard-title-card .title-card-info .title {
  color: var(--Neutral-10, #486284);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 114.286% */
  margin-bottom: 16px;
}
.dashboard .dashboard-title-card .title-card-info p:not(.title) {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard .dashboard-title-card .title-card-info button {
  margin-top: 24px;
}
.dashboard .dashboard-course-card {
  width: 100%;
  min-height: 353px;
  padding: 20px;
  background-color: #FFFFFF;
  margin-top: 35px;
  /* Adjunct instuctor */
}
.dashboard .dashboard-course-card.card-type-current .course-card-info {
  width: 290px;
}
.dashboard .dashboard-course-card .course-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
}
.dashboard .dashboard-course-card .course-card-header h3 {
  font-size: 21px;
}
.dashboard .dashboard-course-card .course-card-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}
.dashboard .dashboard-course-card .course-card-content {
  display: flex;
  gap: 20px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-cover img {
  width: 177px;
  height: 244px;
  border-radius: 24px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-cover.small img {
  width: 60px;
  height: 90px;
  border-radius: 10px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info {
  color: #8CA2C0;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-book-title {
  margin-top: 8px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-dates {
  margin-top: 8px;
  margin-bottom: 20px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .primary {
  width: 187px;
  margin-bottom: 20px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info span {
  display: block;
  color: #486284;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-additional {
  display: flex;
  gap: 10px;
  align-items: start;
  margin-top: 8px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-additional button {
  border: none;
  background: transparent;
  padding: 0;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-additional button > div {
  background-color: #EFF2F6;
  width: 71px;
  height: 78px;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .dashboard-course-card .course-card-content .course-card-info .course-card-additional button span {
  color: #486284;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress {
  background-color: #F6F7F9;
  width: 290px;
  height: 192px;
  border-radius: 8px;
  padding: 10px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress p, .dashboard .dashboard-course-card .course-card-content .course-card-progress button {
  color: #486284;
  font-size: 21px;
  font-weight: 500;
  line-height: 27.34px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress > p {
  font-weight: 600;
  margin-bottom: 8px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress ul li {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress ul li .radio {
  border: 2px solid #486284;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: block;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress ul li .radio.radio-checked {
  border: none;
  background-image: url("../images/layouts/dashboard/radio-checked.svg");
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress ul li p {
  color: #8CA2C0;
}
.dashboard .dashboard-course-card .course-card-content .course-card-progress ul li p button {
  background-color: transparent;
  border: none;
  color: #8CA2C0;
  padding: 0;
}
.dashboard .dashboard-course-card .course-info {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 21px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #486284;
  background: #FFF;
  flex: 1 1 50%;
}
.dashboard .dashboard-course-card .course-info .course-details p {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
}
.dashboard .dashboard-course-card h4 {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 8px;
}
.dashboard .dashboard-course-card h5 {
  color: var(--Neutral-10, #486284);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: 0.15px;
  margin: 8px 0 4px;
}
.dashboard .dashboard-course-card h5:first-child {
  margin-top: 0;
}
.dashboard .dashboard-course-card .course-card-actions {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 1 50%;
  border-radius: 8px;
  background: var(--Light-Gray-2, #F6F7F9);
}
.dashboard .dashboard-2-column, .dashboard .dashboard-3-column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin-top: 170px;
  margin-bottom: 75px;
  font-family: "Open Sans";
}
.dashboard .dashboard-2-column > div, .dashboard .dashboard-3-column > div {
  flex: 1;
}
.dashboard .dashboard-2-column > div h4, .dashboard .dashboard-3-column > div h4 {
  color: var(--Neutral-10, #486284);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
}
.dashboard .dashboard-2-column > div p, .dashboard .dashboard-3-column > div p {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}
.dashboard .dashboard-2-column .large-image-placeholder, .dashboard .dashboard-3-column .large-image-placeholder {
  border-radius: 24px;
  background: var(--Neutral-1, #EFF2F6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 324px;
}
.dashboard .dashboard-3-column {
  gap: 64px;
  margin-top: 0;
  margin-bottom: 75px;
  font-family: "Open Sans";
}
.dashboard .dashboard-3-column > div h5 {
  color: var(--Neutral-10, #486284);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
}
.dashboard .dashboard-3-column .large-image-placeholder {
  height: 335px;
}
.dashboard .dashboard-dept-picks {
  margin-top: 49px;
}
.dashboard .dashboard-previous-courses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.dashboard .dashboard-search-courses {
  margin-top: 42px;
}
.dashboard .dashboard-search-courses input[type=search] {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  background: transparent;
  height: 48px;
  padding-left: 20px;
  color: var(--Neutral-10, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.dashboard .dashboard-search-courses input[type=search]::placeholder {
  color: var(--Neutral-10, #486284);
}
.dashboard .dashboard-search-courses .search-body {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
}
.dashboard .dashboard-search-courses .search-body .search-filter {
  width: 264px;
  flex-shrink: 0;
  height: 800px;
  border-radius: 24px;
  background: var(--Neutral-1, #EFF2F6);
  padding: 24px 20px;
}
.dashboard .dashboard-search-courses .search-body .search-filter h2 {
  color: var(--Dark-B, #486284);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 36px;
}
.dashboard .dashboard-search-courses .search-body .search-filter .filter-container {
  margin-bottom: 10px;
}
.dashboard .dashboard-search-courses .search-body .search-results {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.invite-students-modal {
  width: 50vw;
  padding: 28px 48px;
}
.invite-students-modal h1 {
  color: #486284;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px; /* 126.667% */
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 26px;
}
.invite-students-modal .modal-body {
  border-radius: 8px;
  background: #FFF;
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 30px;
}
.invite-students-modal .modal-body img {
  width: 289px;
  height: 289px;
}
.invite-students-modal .modal-body p:first-of-type {
  color: #486284;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.invite-students-modal .modal-body p:last-of-type {
  color: #8CA2C0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.5px;
}

/* Connect to lMS page ---*/
.page-prompt {
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: 3rem;
}

.lms-logo {
  text-align: center;
  margin-bottom: 3rem;
}

.step-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
}

.matched-courses {
  max-width: 660px;
  margin: 0 auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  border-radius: 10px;
  background: #FFF;
  margin-bottom: 3rem;
}
.matched-courses p {
  color: #486284;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.matched-courses .pill {
  border-radius: 10px;
  background: #EFF2F6;
  padding: 10px 20px;
  text-align: center;
  max-width: 280px;
  margin: 0 auto;
  cursor: pointer;
}
.matched-courses .pill.selected {
  border: 1px solid #486284;
  background: #FFF;
}
.matched-courses .pill p {
  color: #486284;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.matched-courses .pill h4 {
  color: #486284;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.matched-courses ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.title-details {
  width: 100vw;
  overflow: hidden;
}
.title-details .title-details-hero {
  display: flex;
  gap: 50px;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  padding: 0px;
  height: auto;
  padding: 75px 40px 0 40px;
  overflow: hidden;
}
.title-details .title-details-hero:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 90%;
  background-color: #151515;
  clip-path: ellipse(117.5% 60% at 100% 15%);
}
.title-details .title-details-hero .dPkxvt {
  left: 0px;
  top: 0px;
  width: 100%;
  clip-path: ellipse(117.5% 52% at 100% 15%);
  position: absolute !important;
  min-height: 100%;
}
.title-details .title-details-hero .dPkxvt::before {
  --color--dots: #12b2a6;
  width: 400px;
  mask-image: url("../images/layouts/titleDetails/texture-halftone-desktop.svg");
  display: block;
  content: "";
  position: absolute;
  top: 0px;
  height: 100%;
  opacity: 0.1;
  mask-size: 100%;
  mask-repeat: repeat-y;
  mask-position: center center;
  background-color: var(--color--dots);
  background-repeat: repeat-y;
  clip-path: polygon(100% 0px, 100% 77%, 83% 100%, 0px 100%, 0px 17%, 15% 0px);
  left: 0px;
  transform: scaleY(-1);
}
.title-details .title-details-hero .dPkxvt::after {
  --color--dots: #12b2a6;
  width: 400px;
  mask-image: url("../images/layouts/titleDetails/texture-halftone-desktop.svg");
  display: block;
  content: "";
  position: absolute;
  top: 0px;
  height: 100%;
  opacity: 0.1;
  mask-size: 100%;
  mask-repeat: repeat-y;
  mask-position: center center;
  background-color: var(--color--dots);
  background-repeat: repeat-y;
  clip-path: polygon(100% 0px, 100% 77%, 83% 100%, 0px 100%, 0px 17%, 15% 0px);
  right: 0px;
  transform: scaleX(-1);
}
.title-details .title-details-hero .title-details-cover {
  z-index: 2;
}
.title-details .title-details-hero .title-details-cover img {
  max-width: 350px;
  height: fit-content;
  max-height: 494px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.title-details .title-details-hero .title-details-cover .title-details-links {
  position: relative;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.title-details .title-details-hero .title-details-cover .title-details-links a, .title-details .title-details-hero .title-details-cover .title-details-links p {
  font-family: "Open Sans";
  color: rgb(21, 21, 21);
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
}
.title-details .title-details-hero .title-details-info {
  color: #fefefe;
  font-family: "Open Sans";
  z-index: 2;
}
.title-details .title-details-hero .title-details-info h1 {
  font-size: 30px;
  margin: 0;
  margin-top: 20px;
  color: inherit;
}
.title-details .title-details-hero .title-details-info .published-by {
  font-size: 14px;
  font-weight: 400px;
  margin: 5px 0;
  line-height: 22.4px;
}
.title-details .title-details-hero .title-details-info p {
  color: inherit;
}
.title-details .title-details-hero .title-details-info ul li {
  font-size: 14px;
  font-weight: 400px;
  margin: 5px 0;
  line-height: 22.4px;
  color: #fefefe;
}
.title-details .title-details-hero .title-details-info ul li b {
  font-weight: 900;
  margin-right: 10px;
  color: inherit;
}
.title-details .title-details-hero .title-details-info .primary {
  width: 260px;
  margin-top: 70px;
}
.title-details .title-details-hero .title-details-info .primary:hover {
  background: #284060;
  color: white;
}
.title-details .title-details-hero .title-details-info .primary:focus, .title-details .title-details-hero .title-details-info .primary:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
}
.title-details .title-details-tabs {
  padding: 40px;
}
.title-details .title-details-tabs .tablist {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 2px 0;
  overflow: hidden;
  border-bottom: 1px solid #dfe1e1;
}
.title-details .title-details-tabs .tablist button {
  background: transparent;
  border: none;
  margin-right: 20px;
  font-weight: 400;
  position: relative;
  padding: 0;
  font-family: "Open Sans";
  font-size: 20px;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  white-space: nowrap;
  line-height: 32px;
}
.title-details .title-details-tabs .tablist button div {
  padding: 5px 0;
  line-height: 24px;
}
.title-details .title-details-tabs .tablist button.active {
  font-weight: 600;
}
.title-details .title-details-tabs .tablist button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  background: #151515;
  border-radius: 2px 2px 0 0;
  width: 100%;
}
.title-details .title-details-tabs .Tabs_tab-content__KOB_3 {
  position: relative;
  display: none;
  border-radius: 8px;
  font-family: "Open Sans";
}
.title-details .title-details-tabs .Tabs_tab-content__KOB_3.Tabs_active__jfNcr {
  display: block;
}
.title-details .title-details-tabs .Tabs_tab-content__KOB_3.Tabs_vertical__iUMxs {
  background-color: var(--color--background-light);
  color: var(--color--text-primary);
  border-radius: var(--corners-semiround-md);
  box-shadow: var(--shadow);
  grid-column: span 10;
}
.title-details .title-details-tabs .Grid_grid__k2cvh {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: center;
  grid-template-columns: repeat(12, 1fr);
}
.title-details .title-details-tabs .enZECL a {
  font-weight: 600;
  text-decoration: none;
  color: #007a9c;
}
.title-details .title-details-tabs .ecevFT {
  padding-top: 30px;
  grid-column: span 12;
}
.title-details .title-details-tabs .eSkdxn {
  max-height: 406px;
  overflow: hidden;
}
.title-details .title-details-tabs .edqyDW {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin: 30px 0;
  text-align: center;
}
.title-details .title-details-tabs .cRxKch {
  display: flex;
  height: 1px;
  background: #dfe1e1;
  width: 40%;
  align-self: center;
}
@media screen and (max-width: 1440px) {
  .title-details .title-details-tabs .cRxKch {
    width: 45%;
  }
}
.title-details .title-details-tabs .fuFZoe {
  position: relative;
  margin: 0px;
  border: 0px;
  background: none;
  display: inline-flex;
  color: #007a9c;
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 165px;
  padding: 0 10px;
}
.title-details .title-details-tabs .wHitE {
  position: relative;
  top: 2px;
  margin-left: 5px;
  width: 16px;
  height: 16px;
  mask-image: url("../images/layouts/titleDetails/chevron-down.svg");
  background-color: #007a9c;
}
.title-details .title-details-tabs .hOyGYP {
  display: none;
}
.title-details .title-details-tabs .hAgHKv {
  padding-top: 20px;
  grid-column: span 6;
  margin: 0px;
}
.title-details .title-details-tabs .leSDTE {
  text-align: left;
  background-color: #fff;
}
.title-details .title-details-tabs p, .title-details .title-details-tabs ul li {
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}
.title-details .title-details-tabs ul {
  padding: 0px 0px 0px 17px;
}
.title-details .title-details-tabs ul li {
  list-style-type: disc;
}
.title-details .title-details-tabs h3 {
  font-size: 30px;
  line-height: 36px;
  color: rgb(21, 21, 21);
  font-weight: 400;
  margin-bottom: 20px;
}
.title-details .title-details-tabs h4 {
  font-size: 24px;
  line-height: 28.8px;
  color: rgb(21, 21, 21);
  font-weight: 400;
  margin-bottom: 20px;
}
.title-details .title-details-tabs strong {
  font-weight: 645;
}

.welcome-page {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 72px);
  min-width: 100%;
  background: #CED7E4;
}
.welcome-page .builder-group {
  margin-bottom: 347px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.nav .logo {
  margin-left: 98px;
}
.nav ol {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 48px;
}
.nav ol li button {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* body/small */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.nav ol li:nth-child(4) {
  /* Styles for the last <li> element */
  margin-right: 98px;
}
.nav span {
  background: transparent;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter, sans-serif;
}
.nav button {
  /* Remove default button border */
  border: none;
  /* Remove default button background color */
  background-color: transparent;
  /* Remove default button outline */
  outline: none;
  /* Optionally, you can remove padding and margin */
  padding: 0;
  margin: 0;
  /* Optionally, you can remove text decoration */
  text-decoration: none;
}

.search-select {
  margin-top: 0;
  margin-bottom: 31px;
}

/* Apply styles to the placeholder text */
.select__single-value.css-1dimb5e-singleValue, #react-select-5-placeholder {
  color: var(--Neutral-10, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}

/************BackDrop ***********/
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: transparent;
  pointer-events: auto;
  z-index: 20;
}

/********* MODAL STYLES **********/
.access-modal {
  background-color: #F3F2F5;
  display: flex;
  position: absolute;
  top: 79px;
  right: 0;
  margin: 0.5rem;
  overflow: hidden;
  z-index: 21;
  border-radius: 2vh;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

/********* MODAL STYLES **********/
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 348px;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: white;
  color: var(--card-text-color);
  border-radius: 0.3rem;
  outline: 0;
  border-width: 0;
  border-radius: 8px;
  border: 1px solid var(--Dark-B, #486284);
  background: #FFF;
}

.modal-header {
  border-bottom: 1px solid #e8e8f7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 15px;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-header .modal-title {
  margin-bottom: 0;
}

.modal-title {
  font-size: 16px;
  font-weight: 900;
  color: var(--card-text-color);
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  padding: 20px;
  height: 100%;
}

.modal-body-content {
  height: 100%;
}

#login-form {
  height: 168.5px;
}

/**********Close Btn *********/
.btn.icon-btn-18 {
  /* min-width: auto; */
  /* padding: 0.5rem 0.75rem; */
  display: flex;
  align-items: center;
  height: 29px;
  /* padding-top: 12px; */
  padding-right: 12.505px;
  padding-left: 12.505px;
  border: 0;
  margin: 0;
  background-color: rgba(30, 52, 121, 0.1);
  color: white;
}

.modal-title-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-form {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modal-inputs {
  margin-left: 15px;
}

.material-icons.close {
  font-size: 18px;
  /* font-size: 9.37px; */
}

section.course-setup,
.dashboard {
  background: #CED7E4 url(../images/navbar/university_bg.png) no-repeat top center;
  background-size: 100% 372px;
}

section.course-setup.nashville {
  background: #CED7E4 url(../images/navbar/nashville_bg.png) no-repeat top center;
  background-size: 100% 372px;
}

section.dashboard.nashville {
  background: #CED7E4 url(../images/navbar/nashville_bg.png) no-repeat top center;
  background-size: 100% 372px;
}

section.course-setup.ivyleague {
  background: #CED7E4 url(../images/navbar/ivy_bg.png) no-repeat top center;
  background-size: 100% 372px;
}

section.dashboard.ivyleague {
  background: #CED7E4 url(../images/navbar/ivy_bg.png) no-repeat top center;
  background-size: 100% 372px;
}

.course-setup,
.dashboard {
  min-height: 500px;
  padding-top: 240px;
  padding-bottom: 4.635rem;
  position: relative;
}
.course-setup::before,
.dashboard::before {
  content: url(../images/college-university.png);
  position: absolute;
  width: 1128px;
  top: 2rem;
  left: 50%;
  transform: translateX(-60%);
}
.course-setup.nashville::before,
.dashboard.nashville::before {
  content: url(../images/college-nashville.png);
}
.course-setup.ivyleague::before,
.dashboard.ivyleague::before {
  content: url(../images/college-ivy.png);
}

.step-indicator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  gap: 2.25rem;
  padding-bottom: 2.25rem;
  border-bottom: 1px solid #90A3BF;
  margin-bottom: 1.25rem;
}
.step-indicator li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.44px;
  color: rgba(72, 98, 132, 0.6);
  background-color: #F6F7F9;
  position: relative;
}
.step-indicator li.selected {
  color: rgb(72, 98, 132);
  font-weight: 600;
}
.step-indicator li:last-child::after {
  display: none;
}
.step-indicator li::after {
  content: "";
  height: 1px;
  width: 2.25rem;
  position: absolute;
  top: 40%;
  right: -2.25rem;
  background-color: #486284;
  opacity: 0.6;
}

.page-layout {
  background-color: #F6F7F9;
  border-radius: 8px;
  max-width: 1128px;
  margin: 0 auto;
  padding: 1rem 3rem 3rem;
  min-height: 1128px;
}
.page-layout h1 {
  color: #486284;
  text-align: center;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 2.5rem;
}
.page-layout h2 {
  color: #486284;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 1.5rem;
}
.page-layout .step-instructions {
  max-width: 680px;
  margin: 0 auto 2.5rem auto;
  line-height: 1.5;
}

.selected-product {
  display: flex;
  gap: 2.5rem;
  background-color: #fff;
  padding: 2rem 1.5rem;
  margin: 0 auto 2.5rem;
  max-width: 530px;
}
.selected-product img {
  max-width: 5.5rem;
  height: auto;
  display: block;
}
.selected-product h3 {
  color: #486284;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}
.selected-product p {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #8CA2C0;
}
.selected-product.large {
  max-width: 830px;
  padding: 1.25rem;
}
.selected-product.large img {
  max-width: 9.8125rem;
  height: auto;
}
.selected-product.large h3 {
  font-size: 1.75rem;
}
.selected-product.large p {
  font-size: 1rem;
}

.packages {
  max-width: 870px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.packages .header {
  text-align: center;
  padding: 0 0 0.875rem 0;
  border-bottom: 1px solid #90A3BF;
}
.packages .header span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
}
.packages h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  /* 33px */
  letter-spacing: -0.44px;
}
.packages .col {
  align-content: center;
}
.packages ul {
  margin: 1.25rem auto 0;
  max-width: 250px;
}
.packages ul li {
  background: url(../images/icons/check_unselected.png) no-repeat 5px 5px;
  padding-left: 2.25rem;
  color: #8CA2C0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.62;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
  max-width: 250px;
}
.packages ul li.included {
  background-image: url(../images/icons/check_selected.png);
}
.packages button {
  max-width: 250px;
  margin: 0 auto;
  display: block;
}

.choose-type {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 3rem;
}
.choose-type > div {
  flex: 1 0 50%;
  max-width: 320px;
  background-color: #EFF2F6;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  min-height: 275px;
}
.choose-type > div h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.choose-type > div p {
  color: #8CA2C0;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  flex: 1;
}

.student-access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
  align-items: center;
}
.student-access p {
  display: flex;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-bottom: 1.25rem;
  width: 594px;
}

.create-course-form {
  max-width: 680px;
  margin: 0 auto;
}
.create-course-form > p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-bottom: 3.5rem;
}
.create-course-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
}
.create-course-form .form-control {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;
}
.create-course-form .form-control label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
}
.create-course-form .form-control input,
.create-course-form .form-control select {
  border-radius: 10px;
  border: 1px solid #486284;
  padding: 0.75rem 1.25rem;
}
.create-course-form .dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 56px;
  width: 100%;
}
.create-course-form .form-action {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.create-course-form .form-action button {
  min-width: 172px;
}

.confirm-create-course {
  max-width: 830px;
  margin: 0 auto;
}
.confirm-create-course .step-priompt {
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-bottom: 3.5rem;
}
.confirm-create-course .container {
  width: 864px;
}
.confirm-create-course .container .lms-step-priompt {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-bottom: 3.5rem;
  max-width: 830px;
}
.confirm-create-course h3 {
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-bottom: 1.125rem;
}
.confirm-create-course .summary {
  padding: 1.25rem;
  background: #fff;
  position: relative;
  margin-bottom: 2.75rem;
}
.confirm-create-course .summary h4 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.14;
  margin-bottom: 1rem;
}
.confirm-create-course .summary ul li {
  color: #8CA2C0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  /* 160% */
  letter-spacing: 0.5px;
}
.confirm-create-course .summary button.secondary {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  min-width: 128px;
}
.confirm-create-course .summary button.secondary[disabled] {
  cursor: not-allowed;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Light-B, #CED7E4);
  color: white;
}
.confirm-create-course .step-action {
  display: flex;
  justify-content: flex-end;
}
.confirm-create-course .step-action button {
  min-width: 128px;
}

/* Coursesetup modal..*/
.modal.setup {
  width: 800px;
  height: 400px;
  padding: 1.75rem 3rem;
}
.modal.setup h2 {
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.5px;
  margin-bottom: 1.875rem;
}
.modal.setup ul li {
  color: #8CA2C0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  opacity: 0;
  transition: opacity 500ms ease-out;
}
.modal.setup ul li:nth-child(1) {
  transition-delay: 500ms;
}
.modal.setup ul li:nth-child(2) {
  transition-delay: 1000ms;
}
.modal.setup ul li:nth-child(3) {
  transition-delay: 1500ms;
}
.modal.setup ul li.fade-in {
  opacity: 1;
}
.modal.setup ul li.hideaway {
  animation-name: hide;
  animation-duration: 100ms;
  animation-delay: 500ms;
  animation-iteration-count: 1;
}

@keyframes hide {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
.connect-to-bookstore,
.connect-to-lms {
  text-align: center;
  padding: 3.25rem 0;
}
.connect-to-bookstore p,
.connect-to-lms p {
  color: var(--Dark-B, #486284);
  text-align: center;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-bottom: 3.5rem;
}
.connect-to-bookstore .step-actions,
.connect-to-lms .step-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
}
.connect-to-bookstore .step-actions button,
.connect-to-lms .step-actions button {
  min-width: 128px;
}

.setup-complete-message {
  text-align: center;
  margin: 2.25rem auto 3rem auto;
  max-width: 820px;
}
.setup-complete-message p {
  color: #486284;
  text-align: center;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-bottom: 1.5rem;
}
.setup-complete-message p:nth-child(2) {
  margin-bottom: 2rem;
}

.val-role {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
}

.welcome-content .adopt-btn,
.signin-form-content .adopt-btn {
  margin-top: 25px;
}

.welcome-content .adopt-btn-group,
.signin-form-content .adopt-btn-group,
.create-account-form-content .adopt-btn-group,
main.validate-content .adopt-btn-group,
.link-account .adopt-btn-group,
.linking-success .adopt-btn-group,
form.validate-educator-content .adopt-btn-group,
.validate-complete-content .adopt-btn-group,
.title-select-content .adopt-btn-group,
.account-settings-content .adopt-btn-group,
.school-edit-content .adopt-btn-group {
  display: flex;
  justify-content: center;
}
.welcome-content .adopt-btn,
.signin-form-content .adopt-btn,
.create-account-form-content .adopt-btn,
main.validate-content .adopt-btn,
.link-account .adopt-btn,
.linking-success .adopt-btn,
form.validate-educator-content .adopt-btn,
.validate-complete-content .adopt-btn,
.title-select-content .adopt-btn,
.account-settings-content .adopt-btn,
.school-edit-content .adopt-btn {
  margin-bottom: 35px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Neutral-10, #486284);
  color: var(--Neutral-0, #FFF);
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.welcome-content .adopt-btn:hover,
.signin-form-content .adopt-btn:hover,
.create-account-form-content .adopt-btn:hover,
main.validate-content .adopt-btn:hover,
.link-account .adopt-btn:hover,
.linking-success .adopt-btn:hover,
form.validate-educator-content .adopt-btn:hover,
.validate-complete-content .adopt-btn:hover,
.title-select-content .adopt-btn:hover,
.account-settings-content .adopt-btn:hover,
.school-edit-content .adopt-btn:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.welcome-content .adopt-btn:focus,
.welcome-content .adopt-btn:focus,
.signin-form-content .adopt-btn:focus,
.signin-form-content .adopt-btn:focus,
.create-account-form-content .adopt-btn:focus,
.create-account-form-content .adopt-btn:focus,
main.validate-content .adopt-btn:focus,
main.validate-content .adopt-btn:focus,
.link-account .adopt-btn:focus,
.link-account .adopt-btn:focus,
.linking-success .adopt-btn:focus,
.linking-success .adopt-btn:focus,
form.validate-educator-content .adopt-btn:focus,
form.validate-educator-content .adopt-btn:focus,
.validate-complete-content .adopt-btn:focus,
.validate-complete-content .adopt-btn:focus,
.title-select-content .adopt-btn:focus,
.title-select-content .adopt-btn:focus,
.account-settings-content .adopt-btn:focus,
.account-settings-content .adopt-btn:focus,
.school-edit-content .adopt-btn:focus,
.school-edit-content .adopt-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.welcome-content .adopt-btn {
  width: 467px;
  margin-bottom: 63px;
}

.title-select-content footer button.adopt-btn,
.validate-complete-content footer button.adopt-btn {
  width: 307px;
}

.signin-form-content .adopt-btn {
  width: 359px;
}

.school-edit-content .row .adopt-btn {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.account-settings-content .adopt-btn {
  margin-top: 0;
  width: 191px;
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Neutral-10, #486284);
}

form.validate-educator-content footer button.adopt-btn,
main.validate-content footer button.adopt-btn {
  width: 307px;
}

.validate-educator-content .row .col .adopt-btn,
.validate-content .row .col .adopt-btn {
  width: 223px;
}

.link-account footer .row .col .left.adopt-btn, .link-account footer button.right.adopt-btn, .lms.right.adopt-btn.disabled .linking-success footer .row .col button.left.adopt-btn {
  display: flex;
  width: 307px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

/** Invert Button Background **/
form.validate-educator-content footer button.adopt-btn:hover,
main.validate-content footer button.adopt-btn:hover,
.link-account footer .row .col button.left.adopt-btn:hover,
.linking-success footer .row .col button.left.adopt-btn:hover,
validate-content .row .col .adopt-btn,
.validate-educator-content .row .col .adopt-btn,
.school-edit-content .left.adopt-btn {
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  color: var(--Neutral-10, #486284);
  background: var(--Neutral-0, #FFF);
}

.school-edit-content .left.adopt-btn,
.school-edit-content .right.adopt-btn {
  width: 50%;
}

main.validate-content footer div.row .col button.adopt-btn,
.link-account footer button.right.adopt-btn,
.lms.right.adopt-btn.disabled {
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  color: var(--Neutral-10, #486284);
  background: var(--Neutral-0, #FFF);
}

form.validate-educator-content .row .col .adopt-btn:hover,
main.validate-content footer div.row .col button.adopt-btn:hover,
.link-account footer button.right.adopt-btn:hover,
.school-edit-content .left.adopt-btn:hover {
  background-color: #486284;
  color: #FFF;
}

form.validate-educator-content .row .col .adopt-btn:focus,
.validate-content .row .col .adopt-btn:focus,
.link-account footer .row .col button.left.adopt-btn:focus,
.link-account footer button.right.adopt-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

button.disabled {
  cursor: not-allowed;
}

.lms-launch .course-group {
  display: flex;
  justify-content: center;
}
.lms-launch .launch-btn {
  display: flex;
  width: 549px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  margin-bottom: 184px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Neutral-10, #486284);
  color: var(--Neutral-0, #FFF);
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.lms-launch .launch-btn:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.lms-launch .launch-btn:focus,
.lms-launch .launch-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.welcome-page .getHelp, .dashboard .getHelp {
  position: fixed;
  left: 0;
  top: 45%;
  z-index: 100;
}

.validate-educator-content .header .input-group .title-chip-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 500px;
}
.validate-educator-content .header .input-group .row .col input, .validate-educator-content .header .input-group .row .col input::placeholder {
  color: var(--Neutral-10, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.validate-educator-content .header .input-group .row .chip-container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 398px;
}

.course-display-container {
  display: flex;
  background: var(--D-Gray, #AAB5C3);
}
.course-display-container nav {
  display: inline-flex;
  padding: 36px 16px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: var(--theme-transparent-light-8, rgba(254, 254, 254, 0.08));
}
.course-display-container nav .pearson-icon {
  margin-bottom: 64px;
}
.course-display-container nav ol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.course-display-container .course-content {
  display: flex;
  flex-direction: column;
}
.course-display-container .course-content .lms-course-header {
  padding: 0 36px;
}
.course-display-container .course-content .lms-course-header .header-title {
  color: var(--Dark-B, #486284);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/title/Subtitle 2 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
}
.course-display-container .course-content .lms-course-header .right-nav-title {
  color: var(--Theme-text-darkTheme-Default, #FEFEFE);
  text-align: center;
  /* Typography/button/Button Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 200% */
  letter-spacing: 0.46px;
}

.welcome-page .welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-page .welcome-content .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: 0.5px;
  margin-bottom: 35px;
  margin-top: 35px;
}
.welcome-page .welcome-content .pearson-courseware {
  margin-bottom: 77px;
  width: 570px;
  color: var(--Dark-B, #486284);
  text-align: center;
  /* Header02 */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.welcome-page .welcome-content .builder-group {
  display: flex;
  flex-direction: column;
  width: 535px;
  background: #F6F7F9;
  border-radius: 8px;
  margin-bottom: 347px;
}
.welcome-page .welcome-content .builder-group .title {
  margin-top: 39px;
  margin-left: 49px;
  margin-right: 65px;
  margin-bottom: 9px;
  color: var(--Dark-B, #486284);
  text-align: center;
  /* Header02 */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.welcome-page .welcome-content .builder-group .main {
  width: 450px;
  padding-top: 39px;
  margin-left: 32px;
  margin-right: 36px;
  margin-bottom: 45px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}

.welcome-page .describe-user-college-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-page .describe-user-college-content .school-auto-fill {
  width: 358px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}

.welcom-page .form-group.college-val {
  margin-top: 35px;
}

.welcome-page .title-search-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-page .title-search-content .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: 0.5px;
  margin-bottom: 102px;
  margin-top: 35px;
}
.welcome-page .title-search-content .builder-group {
  display: flex;
  flex-direction: column;
  width: 683px;
  height: 211px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  padding-left: 28px;
  padding-right: 39px;
}
.welcome-page .title-search-content .builder-group .title {
  margin-top: 39px;
  margin-left: 49px;
  margin-right: 65px;
  margin-bottom: 9px;
  color: var(--Dark-B, #486284);
  text-align: center;
  /* Header02 */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.welcome-page .title-search-content .builder-group .form-group {
  height: 100%;
}
.welcome-page .title-search-content .builder-group .main {
  margin-top: 26px;
  margin-left: 32px;
  margin-right: 36px;
  margin-bottom: 42px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  width: 480px;
  height: 64px;
}
.welcome-page .title-search-content .builder-group .adopt-btn-group {
  display: flex;
  justify-content: center;
}
.welcome-page .title-search-content .builder-group .adopt-btn {
  width: 467px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Neutral-10, #486284);
  color: var(--Neutral-0, #FFF);
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.welcome-page .title-search-content .builder-group .adopt-btn:hover {
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.welcome-page .title-search-content .builder-group .adopt-btn:focus,
.welcome-page .title-search-content .builder-group .adopt-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3c8DE4;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.welcome-page .option-container {
  display: flex;
  gap: 10px;
}
.welcome-page .option-container .option-details p + p {
  margin-top: 5px;
}

.signin-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  height: 970px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
}
.signin-form-content .signin-header {
  margin-top: 9px;
  margin-bottom: 48px;
}
.signin-form-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.signin-form-content .create-account-link {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
}
.signin-form-content .title {
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}
.signin-form-content .login-group {
  display: flex;
  flex-direction: column;
}
.signin-form-content .login-group .form-group .label-element {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  width: 360px;
  height: auto;
  margin-bottom: 9px;
}
.signin-form-content .login-group .form-group .label-element .label-icon {
  margin-left: 15px;
  width: 17.99px;
  height: 18.89px;
}
.signin-form-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.signin-form-content .login-group .form-group .row {
  margin-bottom: 35px;
  width: 360px;
}
.signin-form-content .login-group .form-group .row .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  width: 360px;
}
.signin-form-content .login-group .form-group div.row:nth-child(2) {
  margin-top: 35px;
}
.signin-form-content .login-group .row.signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.signin-form-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.signin-form-content .login-group button.adopt-btn {
  margin-top: 25px;
  margin-bottom: 35px;
}

.create-account-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  height: 970px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
}
.create-account-form-content .header {
  margin-top: 9px;
  margin-bottom: 96px;
}
.create-account-form-content div.segment.create-account,
.create-account-form-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .title {
  text-align: center;
  /* title/large */
  font-family: "OM Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group {
  display: flex;
  flex-direction: column;
}
.create-account-form-content .login-group .form-group .name-group {
  display: flex;
  flex-direction: row;
}
.create-account-form-content .login-group .form-group .label-element {
  font-size: 16px;
  line-height: 24px;
}
.create-account-form-content .login-group .form-group .checkbox.label-element {
  font-size: 20px;
  line-height: 32px;
}
.create-account-form-content .login-group .form-group .label-element {
  font-style: normal;
  font-weight: 400;
  /* 150% */
  letter-spacing: 0.5px;
  height: auto;
  margin-bottom: 9px;
}
.create-account-form-content .login-group .form-group .label-element .label-icon {
  margin-left: 10px;
  width: 17.99px;
  height: 18.89px;
}
.create-account-form-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.create-account-form-content .login-group .segment .col input {
  width: 267px;
}
.create-account-form-content .login-group .row .col input {
  width: 550px;
}
.create-account-form-content .login-group .row,
.create-account-form-content .login-group .segment {
  margin-bottom: 35px;
}
.create-account-form-content .login-group .row .col input,
.create-account-form-content .login-group .segment .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
}
.create-account-form-content .login-group .form-group .name-group div.segment:nth-child(2) {
  margin-left: 18px;
}
.create-account-form-content .login-group .signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.create-account-form-content .create-btn {
  margin-top: 20px;
  width: 100%;
}

.create-account-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  height: 970px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
}
.create-account-form-content .header {
  margin-top: 9px;
  margin-bottom: 96px;
}
.create-account-form-content div.segment.create-account,
.create-account-form-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .title {
  text-align: center;
  /* title/large */
  font-family: "OM Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group {
  display: flex;
  flex-direction: column;
}
.create-account-form-content .login-group .form-group .name-group {
  display: flex;
  flex-direction: row;
}
.create-account-form-content .login-group .form-group .label-element {
  font-size: 16px;
  line-height: 24px;
}
.create-account-form-content .login-group .form-group .checkbox.label-element {
  font-size: 20px;
  line-height: 32px;
}
.create-account-form-content .login-group .form-group .label-element {
  font-style: normal;
  font-weight: 400;
  /* 150% */
  letter-spacing: 0.5px;
  height: auto;
  margin-bottom: 9px;
}
.create-account-form-content .login-group .form-group .label-element .label-icon {
  margin-left: 10px;
  width: 17.99px;
  height: 18.89px;
}
.create-account-form-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.create-account-form-content .login-group .segment .col input {
  width: 267px;
}
.create-account-form-content .login-group .row .col input {
  width: 550px;
}
.create-account-form-content .login-group .row,
.create-account-form-content .login-group .segment {
  margin-bottom: 35px;
}
.create-account-form-content .login-group .row .col input,
.create-account-form-content .login-group .segment .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
}
.create-account-form-content .login-group .form-group .name-group div.segment:nth-child(2) {
  margin-left: 18px;
}
.create-account-form-content .login-group .signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.create-account-form-content .create-btn {
  margin-top: 20px;
  width: 100%;
}

.validate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
.validate-content .header {
  display: flex;
  width: 864px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 35px;
}
.validate-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
.validate-content .header .row {
  width: 759px;
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.validate-content .header .row section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.validate-content footer {
  display: flex;
  gap: 16px;
  padding-left: 102.5px;
  padding-right: 103.5px;
  margin-bottom: 498px;
}
.validate-content footer .row {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.validate-content footer .row .col {
  display: flex;
  width: 223px;
}

.validate-educator-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
.validate-educator-content .header {
  display: flex;
  width: 864px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 35px;
}
.validate-educator-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
.validate-educator-content .header .input-group {
  display: flex;
  flex-direction: column;
  color: var(--Neutral-7, #8CA2C0);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.validate-educator-content .header .input-group .row {
  margin-bottom: 35px;
}
.validate-educator-content .header .input-group .form-group .search-select {
  width: 358px;
}
.validate-educator-content .header .input-group .form-group .label-element,
.validate-educator-content .header .input-group .row .label-element {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  height: auto;
  margin-bottom: 9px;
}
.validate-educator-content .header .input-group .form-group .label-element label,
.validate-educator-content .header .input-group .row .label-element label {
  display: flex !important;
  align-items: center !important;
}
.validate-educator-content .header .input-group .form-group .label-element label span.label-icon,
.validate-educator-content .header .input-group .row .label-element label span.label-icon {
  margin-left: 15px;
  width: 17.99px;
  height: 18.89px;
}
.validate-educator-content .header .input-group .image-container {
  margin-bottom: 48px;
  width: 358px;
}
.validate-educator-content .header .input-group .row .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  width: 358px;
}
.validate-educator-content footer {
  display: flex;
  gap: 16px;
  padding-left: 102.5px;
  padding-right: 103.5px;
  margin-bottom: 84px;
}
.validate-educator-content footer button.adopt-btn {
  margin-bottom: 0 !important;
}
.validate-educator-content footer .row {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.validate-educator-content footer .row .col {
  display: flex;
  width: 223px;
}

.validate-educator-content {
  width: 864px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.validate-educator-content .status-message {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Header02 */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 114.286% */
  margin-top: 83px;
  margin-bottom: 24px;
}
.validate-educator-content .email-message {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  margin: 35px 132px 136px 132px;
}
.validate-educator-content .spinner-container {
  display: flex;
  width: 744px;
  height: 379px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--Neutral-1, #EFF2F6);
}

.validate-complete-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
.validate-complete-content .header {
  display: flex;
  width: 864px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 35px;
}
.validate-complete-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
.validate-complete-content .row {
  width: 759px;
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  margin-top: 83px;
  margin-bottom: 89px;
  /* Body: Desktop */
}
.validate-complete-content .row section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.validate-complete-content .row section p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.validate-complete-content footer {
  display: flex;
  gap: 16px;
  padding-left: 102.5px;
  padding-right: 103.5px;
  margin-bottom: 84px;
}
.validate-complete-content footer button.adopt-btn {
  margin-bottom: 0 !important;
}

.dashboard-find-presets div .css-1esk77d-control, .dashboard-find-presets div .css-16zdoy9-control, .dashboard-find-presets div .css-1nmdiq5-menu {
  width: 360px;
}
.dashboard-find-presets div .label-group {
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 10px;
}
.dashboard-find-presets div .label-group label {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
.dashboard-find-presets div .label-group .label-icon {
  margin-left: 10px;
  width: 17.99px;
  height: 18.89px;
}
.dashboard-find-presets div .title-chip-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 500px;
}

.create-account-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
  gap: 30px;
  padding-left: 261px;
  padding-right: 261px;
}
.create-account-content .header {
  display: flex;
  width: 864px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-account-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 50px;
}
.create-account-content .avatar {
  width: 200px;
  /* width + 2*padding */
  height: 200px;
  /* height + 2*padding */
  padding: 10px;
  border-radius: 100px;
  background: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.create-account-content .avatar .edit-btn {
  display: inline-flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: #FFF;
  color: var(--Neutral-10, #486284);
  position: absolute;
  bottom: -5px;
  right: 65px;
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.create-account-content .subheader {
  display: flex;
  width: 600px;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.create-account-content .info-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0.5vh;
  border: 1px solid var(--Dark-B, #486284);
  width: 600px;
}
.create-account-content .info-container .user-info {
  display: flex;
  flex-direction: row;
  width: 560px;
  gap: 50px;
  border-bottom: 1px solid #AAB5C3;
  align-items: baseline;
  min-height: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.create-account-content .info-container .user-info .info-label {
  width: 100px;
}
.create-account-content .info-container .user-info .info-content {
  width: 289px;
}
.create-account-content .info-container .user-info .edit-btn {
  display: flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.create-account-content .info-container .user-info:last-child {
  border-bottom: none;
  /* Remove border for the last element */
}
.create-account-content .school-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
  border-radius: 0.5vh;
  border: 1px solid var(--Dark-B, #486284);
  width: 600px;
}
.create-account-content .school-container .school-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 560px;
  gap: 50px;
  border-bottom: 1px solid #AAB5C3;
  align-items: baseline;
  min-height: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.create-account-content .school-container .school-info .school {
  width: 400px;
}
.create-account-content .school-container .school-info .school-data span.school-edit-text {
  color: var(--Dark-B, #486284);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.create-account-content .school-container .school-info .edit-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}
.create-account-content .school-container .school-info .edit-buttons .edit-btn {
  display: flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.create-account-content .school-container .school-info .edit-buttons .edit-btn.default {
  background: #CED7E4;
  color: #FFF;
}
.create-account-content .school-container .school-info .edit-buttons .edit-btn.set, .create-account-content .school-container .school-info .edit-buttons .edit-btn.default {
  width: 128px;
}
.create-account-content .school-container .school-info .edit-buttons button.edit-btn.Edit:hover {
  opacity: 0.8;
  background: #486284;
  color: white;
}
.create-account-content .school-container .school-info:last-child {
  border-bottom: none;
  /* Remove border for the last element */
}
.create-account-content .school-container button.modal-toggle-btn {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: var(--Dark-B, #486284);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  margin: 20px 0;
}

.school-edit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  height: 100%;
}
.school-edit-content .header {
  margin-top: 9px;
  margin-bottom: 30px;
}
.school-edit-content div.segment.create-account,
.school-edit-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.school-edit-content .title {
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
}
.school-edit-content .login-group {
  display: flex;
  flex-direction: column;
  width: 383px;
}
.school-edit-content .login-group .form-group .name-group {
  display: flex;
  flex-direction: row;
}
.school-edit-content .login-group .form-group .label-element {
  font-size: 16px;
  line-height: 24px;
}
.school-edit-content .login-group .form-group .label-element {
  font-style: normal;
  font-weight: 400;
  /* 150% */
  letter-spacing: 0.5px;
  height: auto;
  margin-bottom: 9px;
}
.school-edit-content .login-group .form-group .label-element .form-label {
  width: 383px;
}
.school-edit-content .login-group .form-group .label-element .label-icon {
  margin-left: 10px;
  width: 17.99px;
  height: 18.89px;
}
.school-edit-content .login-group .form-group .checkbox.label-element {
  font-size: 20px;
  line-height: 32px;
}
.school-edit-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.school-edit-content .login-group .form-group .label-element.checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.school-edit-content .login-group .form-group .label-element.checkbox-group label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.school-edit-content .login-group .segment .col input {
  width: 267px;
}
.school-edit-content .login-group .row button.adopt-btn.verified {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  background: var(--Light-B, #CED7E4);
  /* Reduce opacity to indicate it's disabled */
  cursor: not-allowed;
  /* Change cursor to indicate it's not clickable */
}
.school-edit-content .login-group .row button.adopt-btn.verify {
  cursor: not-allowed;
}
.school-edit-content .login-group .row .col input {
  width: 100%;
}
.school-edit-content .login-group .row,
.school-edit-content .login-group .segment {
  margin-bottom: 35px;
}
.school-edit-content .login-group .row .col input,
.school-edit-content .login-group .segment .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  color: var(--Neutral-10, #486284);
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.school-edit-content .login-group .form-group .name-group div.segment:nth-child(2) {
  margin-left: 18px;
}
.school-edit-content .login-group .signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.school-edit-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.school-edit-content .login-group footer {
  display: flex;
  gap: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.school-edit-content .login-group footer button.adopt-btn {
  margin-bottom: 0 !important;
}
.lms-launch {
  padding: 0 98px;
  background: var(--Neutral-0, #FFF);
}
.lms-launch main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 98px;
}
.lms-launch .image-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 137px;
  margin-bottom: 38px;
}
.lms-launch .image-group p {
  width: 546px;
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* Label */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

section.dashboard .lms-title-select.page-layout {
  padding: 0;
}
section.dashboard .lms-title-select.page-layout .title-select-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .header {
  display: flex;
  align-items: center !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 94px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row + .row {
  margin-top: 18px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row {
  display: block;
  margin-bottom: 18px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 60px;
  margin-right: 141px;
  width: 927px;
  color: var(--Neutral-10, #486284);
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.text p.section1 {
  /* subtitle/large */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 114.286% */
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.text p.section2 {
  color: var(--Neutral-10, #486284);
  font-family: "DM Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-top: 18px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses {
  display: flex !important;
  flex-direction: column;
  gap: 0;
  max-width: 482px;
  max-height: 329px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card {
  margin-top: 0;
  border-radius: 0.5vh;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header {
  color: var(--Neutral-10, #486284);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header h3 {
  margin-right: 18px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header button.gear {
  padding: 0;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-cover {
  max-width: 177px;
  max-height: 244px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-info {
  color: var(--Neutral-7, #8CA2C0) !important;
  /* Body: Meta */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-info .course-card-book-title {
  margin-top: 0;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card {
  width: 482px !important;
  min-height: 139px !important;
  background-color: white;
  padding: 16px;
  gap: 10px;
  margin-bottom: 0;
  border-radius: 0.5vh;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-cover img {
  max-height: 90px;
  width: auto;
  border-radius: 10px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-info p.title {
  color: var(--Neutral-10, #486284);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 115.789% */
  letter-spacing: 0.15px;
  margin-bottom: 0;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-info p:not(.title) {
  color: var(--Neutral-7, #8CA2C0);
  /* Body: Meta */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
section.dashboard .lms-title-select.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  margin-left: 0;
  margin-right: 0;
}
section.dashboard .lms-title-select.page-layout .title-select-content footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 591px;
}
section.dashboard .lms-title-select.page-layout .title-select-content footer button.adopt-btn {
  margin-bottom: 0 !important;
}

section.dashboard .lms-title-confirm.page-layout {
  padding: 0;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .header {
  display: flex;
  align-items: center !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 94px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row + .row {
  margin-top: 36px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row {
  display: block;
  margin-bottom: 18px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 60px;
  margin-right: 141px;
  width: 927px;
  color: var(--Neutral-10, #486284);
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.text p.section1 {
  /* subtitle/large */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 114.286% */
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.text p.section2 {
  color: var(--Neutral-10, #486284);
  font-family: "DM Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-top: 18px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses {
  display: flex !important;
  flex-direction: column;
  gap: 0;
  max-width: 482px;
  max-height: 329px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card {
  margin-top: 0;
  border-radius: 0.5vh;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header {
  color: var(--Neutral-10, #486284);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header h3 {
  margin-right: 18px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-header button.gear {
  padding: 0;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-cover {
  max-width: 177px;
  max-height: 244px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-info {
  color: var(--Neutral-7, #8CA2C0) !important;
  /* Body: Meta */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .dashboard-previous-courses .dashboard-course-card .course-card-info .course-card-book-title {
  margin-top: 0;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card {
  width: 482px !important;
  min-height: 139px !important;
  background-color: white;
  padding: 16px;
  gap: 10px;
  margin-bottom: 0;
  border-radius: 0.5vh;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-cover img {
  max-height: 90px;
  width: auto;
  border-radius: 10px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-info p.title {
  color: var(--Neutral-10, #486284);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 115.789% */
  letter-spacing: 0.15px;
  margin-bottom: 0;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card .title-card-info p:not(.title) {
  color: var(--Neutral-7, #8CA2C0);
  /* Body: Meta */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content .row section.card-group .search-results .dashboard-title-card p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  margin-left: 0;
  margin-right: 0;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 631px;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content footer button.adopt-btn {
  margin-bottom: 0 !important;
}
section.dashboard .lms-title-confirm.page-layout .title-select-content footer p {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 228.571% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}

.link-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
.link-account .header {
  display: flex;
  width: 864px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 61px;
}
.link-account .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
  margin-top: 9px;
}
.link-account .header p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.link-account .row.image-container {
  margin-bottom: 62px;
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  border-radius: 8px;
  border: 1px solid var(--Dark-B, #486284);
  background: #FFF;
}
.link-account .row.image-container .user-container {
  display: flex;
  gap: 21px;
}
.link-account .row.image-container .user-container .avatar {
  width: 86px;
  /* width + 2*padding */
  height: 82px;
  /* height + 2*padding */
  padding: 10px;
  border-radius: 100px;
  background: var(--Neutral-1, #EFF2F6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.link-account .row.image-container .user-container .account-info p {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.link-account .row.image-container .user-container .account-info p:first-of-type {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.link-account footer {
  display: flex;
  gap: 16px;
  padding-left: 102.5px;
  padding-right: 103.5px;
}
.link-account footer button.adopt-btn {
  margin-bottom: 0 !important;
}

.linking-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
  height: 996px;
}
.linking-account .header {
  margin-bottom: 7px;
}
.linking-account .header .spinner-container {
  display: flex;
  justify-content: center;
}
.linking-account .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  /* title/large */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
}
.linking-account .row.image-container {
  width: 626px;
  height: 455px;
  flex-shrink: 0;
  border-radius: 20px;
  background: url(../images/linking-accounts.png) lightgray 50%/cover no-repeat;
  margin-bottom: 25px;
}
.linking-account h3.title {
  width: 864px;
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}

.linking-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1122px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F6F7F9;
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
  margin-bottom: 98px;
}
.linking-success .header {
  margin-bottom: 7px;
}
.linking-success .header .title {
  color: var(--Neutral-10, #486284);
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: 0.5px;
}
.linking-success .row.image-container {
  width: 626px;
  height: 455px;
  flex-shrink: 0;
  border-radius: 20px;
  background: url(../images/link-success.png) lightgray 50%/cover no-repeat;
  margin-bottom: 25px;
}
.linking-success h3.title span {
  width: 864px;
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.linking-success h3.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.lms-course-header {
  display: flex;
  width: calc(100vw - 143px);
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 38px;
}
.lms-course-header ol {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 48px;
}
.lms-course-header ol li .lms-avatar-group {
  display: flex;
}
.lms-course-header ol, .lms-course-header ul {
  list-style: none;
}

.course-display-container nav {
  background: #b1bbc8;
}
.course-display-container nav ol li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-display-container nav ol li p {
  display: flex;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--Theme-sys-dark-secondary, #94A6FF);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Small - Emphasis */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  /* 20.02px */
  letter-spacing: 0.252px;
}
.course-display-container nav ol li p.regular-text {
  color: var(--Dark-B, #486284);
  font-weight: 400;
}
.course-display-container nav .mode-bottom {
  margin-top: auto;
  margin-bottom: 36px;
}
.course-display-container nav .mode-bottom p {
  display: flex;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--Theme-sys-dark-secondary, #94A6FF);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Small - Emphasis */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  /* 20.02px */
  letter-spacing: 0.252px;
  color: var(--Dark-B, #486284);
  font-weight: 400;
}

.course-content .course-home-container {
  padding: 50.5px 300px 150px 120px;
}
.course-content .course-home-container .course-home-header {
  display: flex;
  width: 357px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  color: var(--Dark-B, #486284);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/headline/H1 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  /* 57.6px */
  letter-spacing: -0.96px;
  margin-bottom: 57.5px;
}
.course-content .course-home-container main {
  display: flex;
  gap: 36px;
}
.course-content .course-home-container main .column {
  display: flex;
  flex-direction: column;
}
.course-content .course-home-container main .column .col-container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.course-content .course-home-container main .column .col-container .content .bottom.row .subtitle-container h3 {
  margin-bottom: 16px;
}
.course-content .course-home-container main .column .col-container h3 {
  color: var(--Dark-B, #486284);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/title/Subtitle 2 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 7.72px;
  /* 30px */
}
.course-content .course-home-container main .column .col-container .row h2 {
  width: 439px;
  display: flex;
  height: 33px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: var(--Theme-text-darkTheme-Default, #FEFEFE);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/title/Subhead 4 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
}
.course-content .course-home-container main .column .col-container .row .resume-btn {
  width: 106px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border-radius: 40px;
  background-color: #94A6FF;
}
.course-content .course-home-container main .column .col-container .row .resume-btn h2 {
  color: var(--Theme-sys-dark-on-secondary, #092582);
  text-align: center;
  /* Typography/button/Button Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.4px;
}
.course-content .course-home-container main .column .col-container .row .subtitle-container {
  display: flex;
  justify-content: space-between;
}
.course-content .course-home-container main .column .col-container .row .subtitle-container h3:nth-of-type(2) {
  display: flex;
  justify-content: center;
  width: 45px;
  margin-right: 36px;
  color: var(--Theme-sys-dark-primary, #5BDDA0);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Medium - Emphasis */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  /* 22.88px */
  letter-spacing: 0.15px;
  border-bottom: 4px solid #5BDDA0;
}
.course-content .course-home-container main .column .col-container .row .header-handles {
  color: white;
}
.course-content .course-home-container main .column .col-container .row .assignments-background {
  height: 632px;
  width: 599px;
  background: var(--Dark-B, #486284);
  border-radius: 0.75vh;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment:not(:last-child) {
  border-bottom: 1px solid #404943;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment {
  display: flex;
  padding: 20.5px 21px 19.5px 21px;
  align-items: center;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .calendar {
  display: flex;
  flex-direction: column;
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Theme-sys-dark-tertiary, #5AD5F9);
  justify-content: center;
  align-items: center;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .calendar h3 {
  display: flex;
  width: 28px;
  height: 17px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Dark-B, #486284);
  text-align: center;
  margin-bottom: 0;
  /* Typography/button/Button Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 200% */
  letter-spacing: 0.46px;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .calendar h3:nth-of-type(2) {
  color: var(--Dark-B, #486284);
  text-align: center;
  /* Typography/button/Button Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.4px;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group {
  width: 472px;
  margin-left: 21px;
  margin-right: 8px;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group h3 {
  color: var(--Theme-text-darkTheme-Default, #FEFEFE);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/title/Subhead 4 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group ol {
  display: flex;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group ol li + li {
  padding-left: 16px;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group ol li:not(:last-child) {
  padding-right: 16px;
  border-right: 1px solid #AAB5C3;
}
.course-content .course-home-container main .column .col-container .row .assignments-background .assignment .assignment-group ol li {
  color: var(--D-Gray, #AAB5C3);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/label/Lable Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 14.4px */
  letter-spacing: 0.72px;
}
.course-content .course-home-container main .column .col-container .row .profile-background {
  height: 86px;
  width: 433px;
  background: var(--Dark-B, #486284);
  border-radius: 0.75vh;
}
.course-content .course-home-container main .column .col-container .row .profile-background .profile-group {
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 433px;
  height: 86px;
  margin-left: 21px;
  margin-right: 8px;
}
.course-content .course-home-container main .column .col-container .row .profile-background .profile-group .instructor-avatar img {
  border-radius: 50px;
}
.course-content .course-home-container main .column .col-container .row .profile-background .profile-group .labels {
  display: flex;
  flex-direction: column;
}
.course-content .course-home-container main .column .col-container .row .profile-background .profile-group .labels h2 {
  color: var(--Theme-text-darkTheme-Default, #FEFEFE);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/title/Subhead 4 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
}
.course-content .course-home-container main .column .col-container .row .profile-background .profile-group .labels h2:last-of-type {
  color: var(--Theme-text-darkTheme-Muted, #B5B5B5);
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  /* 22.88px */
  letter-spacing: 0.15px;
}
.course-content .course-home-container main .column .col-container .row .announce-background {
  height: 263px;
  width: 433px;
  background: var(--Dark-B, #486284);
  border-radius: 0.75vh;
  padding: 16px 24px;
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group .announce-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 8px 16.81px 8px 10.93px;
  resize: none;
  width: 385px;
  overflow: hidden;
  color: var(--Theme-text-darkTheme-Muted, #B5B5B5);
  background-color: #486284;
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  /* 20.02px */
  letter-spacing: 0.252px;
  border-radius: 8px;
  border: 1px solid #414A47;
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group .post-group {
  display: flex;
  flex-direction: row;
  gap: 28px;
  height: 40px;
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group .post-group textarea {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 8px 16.81px 8px 10.93px;
  resize: none;
  width: 276px;
  overflow: hidden;
  color: var(--Theme-text-darkTheme-Muted, #B5B5B5);
  background-color: #486284;
  font-feature-settings: "clig" off, "liga" off;
  /* Typography/body/Body Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  /* 20.02px */
  letter-spacing: 0.252px;
  border-radius: 8px;
  border: 1px solid #414A47;
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group .post-group textarea::placeholder {
  color: var(--Theme-text-darkTheme-Muted, #B5B5B5);
}
.course-content .course-home-container main .column .col-container .row .announce-background .announce-group .post-group .post-btn {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid var(--D-Gray, #AAB5C3);
  color: var(--Theme-text-darkTheme-Default, #FEFEFE);
  text-align: center;
  /* Typography/button/Button Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.4px;
}
.course-content .course-home-container main .assigntment-background {
  width: 599px;
  height: 72px;
  display: flex;
  padding: 16px 19px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Dark-B, #486284);
}
.course-content .course-home-container main .assigntment-background .assignment-content {
  display: flex;
}
.course-content .course-home-container main .product-background {
  width: 433px;
  display: inline-flex;
  height: 164px;
  padding-left: 24px;
  padding-right: 83px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Dark-B, #486284);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}

.avatar-display.select-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  align-self: stretch;
  border-radius: 2vh;
  border: 1px solid var(--Dark-B, #486284);
  background: #FFF;
  width: 380px;
}
.avatar-display.select-container .modal-body .modal-body-content main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
}
.avatar-display.select-container .modal-body .modal-body-content main .show-account-btn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Neutral-10, #486284);
  color: var(--Neutral-10, #486284);
  /* body/small */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}
.avatar-display.select-container .modal-body .modal-body-content main .show-account-btn:hover {
  background: #486284;
  color: white;
}

.create-account-form-content,
.modal-toggle-btn {
  display: flex;
  margin-top: 0;
  color: #252525;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.create-account {
  margin-top: 26px;
}

.sign-out-footer {
  border-top: 1px solid #252525;
  width: 100%;
}
.sign-out-footer .modal-footer {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  align-self: stretch;
  justify-content: flex-start;
  padding: 16px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.sign-out-footer .modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.sign-out-footer .modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.lms-.sign-out-footer {
  width: 100%;
}
.lms-.sign-out-footer .modal-footer {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  align-self: stretch;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.lms-.sign-out-footer .modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.lms-.sign-out-footer .modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.avatar {
  width: 106px; /* width + 2*padding */
  height: 102px; /* height + 2*padding */
  padding: 10px;
  border-radius: 100px;
  background: var(--Neutral-1, #EFF2F6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand-list-ctr {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.expand-list-ctr .school-num {
  color: #252525;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.expand-list-ctr .expand-btn {
  display: flex;
  margin-top: 0;
  color: #252525;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-decoration-line: underline;
}

.school.modal {
  cursor: pointer;
}

.school {
  display: flex;
  width: 348px;
  padding: 10px 10px 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  border-radius: 8px;
  border: 1px solid var(--Dark-B, #486284);
}
.school .col {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.school .col .school-data {
  display: flex;
  flex-direction: column;
  color: var(--Light-B-2, #EFF2F6);
}
.school .col .school-data span {
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
  gap: 0;
  padding: 0;
}
.school .col .school-data span.active {
  color: #EFF2F6;
}
.school .col .school-data span.inactive {
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
}

.school.active {
  background: var(--Dark-B, #486284);
}

.school.inactive {
  background: var(--Dark-B, #FFF);
}

.school.inactive.account-modal:hover {
  background: #486284;
  color: white;
}
.school.inactive.account-modal:hover .col .school-data span {
  color: white;
}

.school.inactive.expand {
  display: block;
}

.school.inactive.collapse {
  display: none;
}

.active-tag {
  display: flex;
  padding: 0px 7px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 7px;
  background: var(--Light-B, #CED7E4);
  width: 58px;
  height: 24px;
  margin-left: auto;
}

.active-tag-text {
  color: var(--Dark-B, #486284);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}

.page-in-lms {
  background: #FFF;
  text-align: center;
  padding: 24px;
  border-top: 1px solid #DEE5ED;
}
.page-in-lms > p {
  color: #486284;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.page-in-lms > p:nth-child(2) {
  margin-bottom: 100px;
}
.page-in-lms .steps {
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;
}
.page-in-lms .steps .step {
  border-radius: 8px;
  border: 1px solid #486284;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  max-width: 400px;
}
.page-in-lms .steps .step h2 {
  display: flex;
  width: 113px;
  height: 108px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: #EFF2F6;
  margin: 0 auto;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 82.759%;
  letter-spacing: 0.5px;
}
.page-in-lms .steps .step h2.complete {
  background-image: url(../images/icons_check_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.page-in-lms .steps .step h3 {
  color: #8CA2C0;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}
.page-in-lms .steps .step p {
  color: #486284;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-align: left;
}
